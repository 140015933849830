<template>
  <div class='videoBox'>
    <div class="videoTopBox">
      <iframe ref="fileIframe" :src="videoUrl" frameborder="0" v-if="fileType != 'video'"
              style="width: 100%;height: 50vh"></iframe>
      <video class="video" ref="video" :muted="false" disablePictureInPicture :src="videoUrl"
             v-if="fileType == 'video'"></video>
      <div class="timeBox" v-if="fileType == 'video'">{{ formatTime(currentTime) }}/{{ formatTime(totalTime) }}</div>
      <el-button type="primary" v-if="fileType == 'video'" :icon="videoType?'el-icon-video-pause':'el-icon-video-play'"
                 class="videoBtn" @click="videoPlay"></el-button>
    </div>
    <div class="name"
         :style="{ color: themeColor,borderTop: '2px solid' + themeColor,borderBottom: '2px solid' + themeColor}">
      学习列表
    </div>
    <div class="videoBottomBox">
      <el-button type="primary" class="moveButton" icon="el-icon-arrow-left" circle @click="leftArrow"></el-button>
      <div class="videoBigSwiper">
        <div class="videoSwiper" ref="videoSwiper">
          <div class="videoLittleBox" :style="{border: videoActive == index?'1px solid' + themeColor:''}"
               v-for="(item,index) in videoUrlList" :key="index" @click="chooseVideo(item,index)">
            <video class="littleVideo" disablePictureInPicture :src="item.attachUrl"
                   v-if="item.fileType == 'video'"></video>
            <img src="@/assets/images/image.png" v-if="item.fileType == 'image'">
            <img src="@/assets/images/pdf.png" v-if="item.fileType == 'pdf'">
            <img src="@/assets/images/document.png" v-if="item.fileType == 'document'">
            <img src="@/assets/images/xls.png" v-if="item.fileType == 'xls'">
            <img src="@/assets/images/unknown.png" v-if="item.fileType == 'unknown'">
            <div class="learnName" v-if="item.whetherToWatch == 1" :style="{color: themeColor}">已学习</div>
            <div class="learnName" v-if="item.whetherToWatch == 2">未学习</div>
          </div>
        </div>
      </div>
      <el-button type="primary" class="moveButton" icon="el-icon-arrow-right" circle @click="rightArrow"></el-button>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import website from "@/config/website";
import {Base64} from "js-base64";
import {saveVideo} from "@/api/training/trainingEducation";

export default {
  data() {
    return {
      id: '',
      videoUrl: '',
      videoActive: 0,
      activeIndex: 0,
      activeType: '',
      oldVideoIds: '',
      totalTime: 0,
      currentTime: 0,
      attachTime: '',
      videoUrlList: [],
      interval: null,
      intervalFive: null,
      whetherToWatch: '',
      videoType: true,
      fileType: '',
      videoId: ''
    }
  },
  computed: {
    ...mapGetters(['themeColor'])
  },
  methods: {
    formatTime(seconds) {
      let hours = Math.floor(seconds / 3600);
      let minutes = Math.floor((seconds % 3600) / 60);
      let remainingSeconds = seconds % 60;
      if (hours <= 9) {
        hours = '0' + hours
      }
      if (minutes <= 9) {
        minutes = '0' + minutes
      }
      if (remainingSeconds <= 9) {
        remainingSeconds = '0' + remainingSeconds
      }
      return `${hours}:${minutes}:${remainingSeconds}`;
    },
    clearTime() {
      clearInterval(this.interval);
      clearInterval(this.intervalFive);
    },
    getTrainCourseList(fileCover, progress, id, duration, watchDuration) {
      let data = {
        attachId: "1769548092488818690",
        attachSumTime: duration,
        attachTime: watchDuration,
        attachUrl: fileCover,
        fileType: "video",
        id: id,
        trainingPlanUseId: "1769548276744593410",
        whetherToWatch: progress,
      }
      let dataList = []
      dataList.push(data)
      this.videoUrlList = dataList;
      this.videoId = id;
      this.fileType = this.videoUrlList[0].fileType;
      if (this.fileType == 'video') {
        this.totalTime = duration;
        this.videoUrl = this.videoUrlList[0].attachUrl;
        this.oldVideoIds = this.videoUrlList[0].attachId;
        this.currentTime = Number(this.videoUrlList[0].attachTime);
        this.whetherToWatch = this.videoUrlList[0].whetherToWatch
        this.$nextTick(() => {
          this.getVideoTime();
          this.getFiveSave();
        })
      } else {
        let dataUrl = this.videoUrlList[0].attachUrl;
        this.getKKFileUrl(dataUrl);
      }
    },
    getFileType(fileName) {
      // 获取文件后缀
      var fileExtension = fileName.split('.').pop().toLowerCase();
      // 根据文件后缀确定文件类型
      switch (fileExtension) {
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
          return 'image';
        case 'mp4':
        case 'avi':
        case 'mov':
          return 'video';
        case 'pdf':
          return 'pdf';
        case 'doc':
        case 'docx':
          return 'document';
        case 'xls':
        case 'xlsx':
          return 'xls';
        default:
          return 'unknown';
      }
    },
    doUpdateTrainCourse() {
      if (this.whetherToWatch == 2) {
        let schedule = (Math.floor(this.$refs.video.currentTime) / this.totalTime) * 100;
        schedule = Math.floor(schedule) + '%'
        let currentTime = Math.floor(this.$refs.video.currentTime);
        let data = {
          id: this.videoId,
          watchDuration: currentTime,
          schedule
        }
        saveVideo(data)
      }
    },
    chooseVideo(item, index) {
      this.fileType = item.fileType;
      if (this.fileType == 'video') {
        this.videoUrlList[this.videoActive].attachTime = this.currentTime;
        this.doUpdateTrainCourse();
        this.videoActive = index;
        this.videoUrl = item.attachUrl;
        this.oldVideoIds = item.attachId;
        this.whetherToWatch = item.whetherToWatch;
        this.videoType = true;
        this.currentTime = Number(item.attachTime);
        this.$nextTick(() => {
          clearInterval(this.interval);
          this.getVideoTime();
        })
      } else {
        let dataUrl = item.attachUrl;
        this.getKKFileUrl(dataUrl);
      }
    },
    videoPlay() {
      let video = this.$refs.video;
      if (this.videoType) {
        video.pause();
      } else {
        video.play();
      }
      this.videoType = !this.videoType;
    },
    getVideoTime() {
      let video = this.$refs.video;
      if (this.currentTime != '' || this.currentTime != 0) {
        if (this.whetherToWatch == 2) {
          if (this.currentTime >= 7) {
            video.currentTime = this.currentTime - 3;
          } else {
            video.currentTime = this.currentTime;
          }
        } else {
          video.currentTime = 0;
        }
      }
      video.play();
      video.onloadedmetadata = () => {
        this.totalTime = Math.floor(video.duration);
      };
      this.interval = setInterval(() => {
        this.currentTime = Math.floor(video.currentTime);
        if (this.currentTime == this.totalTime) {
          if (this.videoActive != this.videoUrlList.length - 1) {
            this.videoUrlList[this.videoActive].whetherToWatch = 1;
            this.doUpdateTrainCourse();
            this.videoActive++;
            this.fileType = this.videoUrlList[this.videoActive].fileType;
            if (this.fileType == 'video') {
              this.videoUrl = this.videoUrlList[this.videoActive].attachUrl;
              this.oldVideoIds = this.videoUrlList[this.videoActive].attachId;
              this.whetherToWatch = this.videoUrlList[this.videoActive].whetherToWatch;
              this.videoType = true;
              this.currentTime = Number(this.videoUrlList[this.videoActive].attachTime);
              this.$nextTick(() => {
                clearInterval(this.interval);
                this.getVideoTime();
              })
            } else {
              let dataUrl = this.videoUrlList[this.videoActive].attachUrl;
              this.getKKFileUrl(dataUrl)
            }
          } else {
            this.clearTime();
            this.doUpdateTrainCourse();
          }
        }
      }, 1000)
    },
    getKKFileUrl(dataUrl) {
      this.videoUrl =
        process.env.VUE_APP_BASE_KKFILEURL + "?url=" +
        encodeURIComponent(Base64.encode(dataUrl));
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;
        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide =
            iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
    getFiveSave() {
      this.intervalFive = setInterval(() => {
        this.doUpdateTrainCourse();
      }, 6000)
    },
    leftArrow() {
      if (this.activeIndex == 0) {
        this.$message({
          type: "warning",
          message: "已经到最左侧了",
        });
        return;
      }
      this.activeIndex--;
      this.$refs.videoSwiper.style.transform = 'translateX(-' + this.activeIndex * 25 + '%)';
    },
    rightArrow() {
      if (this.activeIndex == this.videoUrlList.length - 4 || this.videoUrlList.length <= 4) {
        this.$message({
          type: "warning",
          message: "已经到最右侧了",
        });
        return;
      }
      this.activeIndex++;
      this.$refs.videoSwiper.style.transform = 'translateX(-' + this.activeIndex * 25 + '%)';
    }
  }
}
</script>

<style scoped lang="scss">
.videoBox {
  width: 100%;
}

.videoTopBox {
  background-color: #303133;
  position: relative;
}

.timeBox {
  position: absolute;
  right: 3px;
  bottom: 0;
  color: #FFFFFF;
  font-size: 20px;
}

.videoBtn {
  position: absolute;
  bottom: 3px;
  left: 3px;
}

::v-deep .videoBtn i {
  font-size: 22px;
}

.video {
  width: 100%;
  height: 50vh;
}

.videoBottomBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.name {
  width: 100%;
  text-align: center;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  font-size: 24px;
  margin: 6px 0;
}

.videoLittleBox {
  width: calc(25% - 24px);
  margin: 0 12px;
  height: calc(100% - 6px);
  cursor: pointer;
  border: 1px solid transparent;
  flex-shrink: 0;
}

.littleVideo {
  width: 100%;
  height: calc(100% - 27px);
}

.videoLittleBox img {
  width: 100%;
  height: calc(100% - 27px);
}

.moveButton {
  height: 32px;
  margin: calc(10vh - 16px) 0;
}

.videoBigSwiper {
  width: calc(100% - 64px);
  height: 20vh;
  overflow: hidden;
}

.videoSwiper {
  width: 100%;
  height: 100%;
  display: flex;
}

.learnName {
  width: 100%;
  text-align: center;
  color: #2979FF;
  font-size: 16px;
}
</style>
