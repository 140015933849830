<template>
  <div>
    <grid-layout
      ref="gridLayoutVide"
      class="serviceType"
      :table-options="subTableOption"
      :table-data="tableData"
      :table-loading="false"
      @grid-row-detail-click="rowDetail"
    >
      <template #customBtn="{ row, index }">
        <el-button size="small" @click="coursewareDownload(row)" type="text"
        >下载
        </el-button>
      </template>
    </grid-layout>
    <el-dialog
      v-dialog-drag
      title="附件预览"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showFileDialog"
      width="70%"
      :fullscreen="fullscreen"
      class="qmDialog"
    >
      <div class="positionabs">
        <i class="el-icon-full-screen" @click="fullClick()"></i>
      </div>
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="showFileDialog = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import CommonTree from "@/views/components/com_tree";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {getDetail, myTrainingDetail, updateCoursewareAccessTime} from "@/api/training/trainingEducation";
import {downloadFileBlob} from "@/util/util";
import website from "@/config/website";
import {Base64} from "js-base64";

export default {
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
  },
  props: {
    headRefresh: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      fullscreen: false,
      dataForm: {},
      tableData: [],
      coursewareData: [],
      showFileDialog: false,
      fileUrl: "",
      //列表展示字段
      subTableOption: {
        selection: false,
        customAddBtn: true,
        menuWidth: 100,
        linklabel: "coursewareName",
        column: [
          {
            label: "课件名称",
            prop: 'coursewareName',
            align: "left",
            overHidden: true,
          },
          {
            label: "获得课时",
            prop: 'obtainHours',
            align: "center",
            width: 180,
            overHidden: true,
          },
          {
            label: "类型",
            prop: 'coursewareType',
            align: "center",
            width: 180,
            overHidden: true,
          },
        ]
      },
    }
  },
  computed: {},
  methods: {
    fullClick() {
      this.fullscreen = !this.fullscreen;
    },
    coursewareDownload(row) {
      if (!row.obtainHours || row.obtainHours <= 0) {
        updateCoursewareAccessTime(row.id).then(res => {
          this.$message.success("已获得课时");
          this.headRefresh()
        })
      }
      // 课件下载
      if (row.fileId) {
        this.coursewareData.forEach((e) => {
          if (e.id === row.fileId) {
            row = e
          }
        })
        this.$message.success("已开始下载！");
        if (!row.fileName.includes(".")) {
          row.fileName = row.fileName + "." + row.extension
        }
        downloadFileBlob(row.fileCover, row.fileName);
      }
    },
    rowDetail(row) {
      if (!row.obtainHours || row.obtainHours <= 0) {
        updateCoursewareAccessTime(row.id).then(res => {
          this.$message.success("已获得课时");
          this.headRefresh()
        })
      }
      if (row.fileId) {
        this.coursewareData.forEach((e) => {
          if (e.id === row.fileId) {
            row = e
          }
        })
        this.fileUrl = process.env.VUE_APP_BASE_KKFILEURL + "?url=" +
          encodeURIComponent(Base64.encode(row.fileCover));
        this.showFileDialog = true;
        //exls表格预览隐藏打印按钮
        if (process.env.NODE_ENV === "production") {
          const iframe = this.$refs.fileIframe;
          const iframeDocument =
            iframe.contentDocument || iframe.contentWindow.document;

          // 在 iframe 内部隐藏元素
          if (iframeDocument) {
            const elementToHide = iframeDocument.getElementById("button-area");
            if (elementToHide) {
              elementToHide.style.display = "none";
            }
          }
        }
      }
    },
    /**
     * 查看详情
     * @param id
     */
    detail(id) {
      this.tableData = [];
      getDetail(id).then((res) => {
        this.dataForm = res.data.data;
        this.tableData = res.data.data.educationCoursewares
      }).then(() => {
        this.dataForm.taskId
        myTrainingDetail(this.dataForm.taskId).then((res) => {
          let data = res.data.data
          this.coursewareData = data.kmInfoCoursewareList
        })
      })
    },
  },
}
</script>
<style scoped lang="less">
.qmDialog {

  .positionabs {
    position: absolute;
    top: 10px;
    right: 50px;

    i {
      cursor: pointer;
    }

  }

  .el-dialog__footer {
    border-top: 1px solid #e6e6e6;
    padding: 10px !important;

  }

}
</style>
