var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("grid-layout", {
        ref: "gridLayoutVide",
        staticClass: "serviceType",
        attrs: {
          "table-options": _vm.subTableOption,
          "table-data": _vm.tableData,
          "table-loading": false,
        },
        scopedSlots: _vm._u([
          {
            key: "customBtn",
            fn: function ({ row, index }) {
              return [
                row.schedule === "0%" && _vm.type != "view"
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.beginTraining(row)
                          },
                        },
                      },
                      [_vm._v(" 开始培训\n      ")]
                    )
                  : _vm._e(),
                row.schedule !== "0%" && _vm.type != "view"
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.beginTraining(row)
                          },
                        },
                      },
                      [_vm._v(" 继续培训\n      ")]
                    )
                  : _vm._e(),
                _vm.type == "view"
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.beginTraining(row)
                          },
                        },
                      },
                      [_vm._v(" 查看视频\n      ")]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }